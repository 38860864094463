import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

import { LoginComponent } from "./auth/login/login.component";

export const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./public/public.module").then(m => m.PublicModule),
  },
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then(m => m.AuthModule),
  },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
