import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { tokenNotExpired } from "angular2-jwt";
import { PublicModule } from "../public.module";
import { BASE_ENDPOINT } from "../config/app";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  public authToken: any;
  public user: any;
  public headers: Headers;
  protected baseEndPoint = `${BASE_ENDPOINT}`;

  constructor(public http: Http) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.user = JSON.parse(localStorage.getItem("usuario")) || {};
  }

  public logIn(email, password) {
    return this.http.post(`${this.baseEndPoint}/signIn`, {
      email,
      password,
    });
  }

  public recuperarContrasena(email: string) {
    return this.http.post(`${this.baseEndPoint}/forgot`, {
      email,
    });
  }

  public storeUserData(token, user) {
    localStorage.setItem("id_token", token);
    localStorage.setItem("usuario", JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  public forgot(email) {
    const obj = { email };
    return this.http
      .post(this.baseEndPoint + "/forgot", JSON.stringify(obj), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }

  public change(email, password, passwordNew) {
    const obj = { email, password, passwordNew };
    return this.http
      .post(this.baseEndPoint + "/reset", JSON.stringify(obj), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }

  public loadToken() {
    const token = localStorage.getItem("id_token");
    this.authToken = token;
  }
  public logout() {
    this.authToken = null;
    this.user = {};
    localStorage.clear();
  }
  public getToken() {
    this.loadToken();
    return this.authToken;
  }
  public loggedIn() {
    return tokenNotExpired("id_token");
  }
  public getUser() {
    return this.user;
  }
}
